<template>
  <v-card color="grey lighten-3 " elevation="0">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <h4>Total dos produtos</h4>
        </div>
        <div class="text-right">
          <h4>{{ $format.decimal(product_total) }}</h4>
        </div>
      </div>

      <div class="d-flex justify-space-between mt-3" v-if="discount_total > 0">
        <div>
          <span>Descontos</span>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(discount_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <div>
          <span>Icms</span>
          <small> (Base cálculo: {{ $format.decimal(icms_calc_base) }})</small>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(icms_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <div>
          <span>Icms St.</span>
          <small>
            (Base cálculo: {{ $format.decimal(icms_st_calc_base) }})</small
          >
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(icms_st_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <div>
          <span>Ipi</span>
          <small> (Base cálculo: {{ $format.decimal(ipi_calc_base) }})</small>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(ipi_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3" v-if="shipping_total > 0">
        <div>
          <span>Frete</span>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(shipping_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3" v-if="insurance_total > 0">
        <div>
          <span>Seguro</span>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(insurance_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3" v-if="expenses_total > 0">
        <div>
          <span>Outras Despesas</span>
        </div>
        <div class="text-right">
          <span>{{ $format.decimal(expenses_total) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <div>
          <h3>Total da Nota Fiscal</h3>
        </div>
        <div class="text-right">
          <h3>{{ $format.decimal(purchase_total) }}</h3>
        </div>
      </div>

      <!-- <v-row class="d-flex justify-end text-end">
        <v-col cols="3">
          <h3>Total dos produtos: {{ $format.decimal(product_total) }}</h3>
        </v-col>
        <v-col cols="3">
          <h3>Total da compra: {{ $format.decimal(purchase_total) }}</h3>
        </v-col>
      </v-row>

      <v-divider class="my-3" />
      <v-row class="d-flex justify-space-around mt-4">
        <h4>B. C. ICMS: {{ $format.decimal(icms_calc_base) }}</h4>
        <h4>ICMS: {{ $format.decimal(icms) }}</h4>
        <h4>B. C. ICMS ST: {{ $format.decimal(icms_st_calc_base) }}</h4>
        <h4>ICMS ST: {{ $format.decimal(icms_st) }}</h4>
        <h4>B. C. IPI: {{ $format.decimal(ipi_calc_base) }}</h4>
        <h4>IPI: {{ $format.decimal(ipi) }}</h4>
      </v-row>
      <v-row class="d-flex justify-space-around mt-8">
        <h4>Seguro: {{ $format.decimal(insurance_total) }}</h4>
        <h4>Frete: {{ $format.decimal(shipping_total) }}</h4>
        <h4>Outras Despesas: {{ $format.decimal(expenses_total) }}</h4>
      </v-row> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data() {
    return {
      purchase: {},
    };
  },

  computed: {
    discount_total() {
      return this.$calc.sum(this.purchase.purchase_products, "discount");
    },

    icms_calc_base() {
      return this.$calc.sum(this.purchase.purchase_products, "icms_calc_base");
    },

    icms_total() {
      return this.$calc.sum(this.purchase.purchase_products, "icms");
    },

    icms_st_calc_base() {
      return this.$calc.sum(
        this.purchase.purchase_products,
        "icms_st_calc_base"
      );
    },

    icms_st_total() {
      return this.$calc.sum(this.purchase.purchase_products, "icms_st");
    },

    ipi_calc_base() {
      return this.$calc.sum(this.purchase.purchase_products, "ipi_calc_base");
    },

    ipi_total() {
      return this.$calc.sum(this.purchase.purchase_products, "ipi");
    },

    shipping_total() {
      return this.$calc.sum(this.purchase.purchase_products, "shipping");
    },

    expenses_total() {
      return this.$calc.sum(this.purchase.purchase_products, "expenses");
    },

    insurance_total() {
      return this.$calc.sum(this.purchase.purchase_products, "insurance");
    },

    product_total() {
      return this.$calc.sum(this.purchase.purchase_products, "product_total");
      // return this.purchase.purchase_products.reduce(
      //   (sum, row) => (sum = row.quantity * row.price),
      //   0
      // );
    },

    purchase_total() {
      return this.$calc.sum(this.purchase.purchase_products, "purchase_total");
      // return parseFloat(this.product_total) -
      // parseFloat(this.discount_total) +
      // parseFloat(this.icms_st_total) +
      // parseFloat(this.ipi_total) +
      // parseFloat(this.shipping_total) +
      // parseFloat(this.expenses_total) +
      // parseFloat(this.insurance_total)
    },
  },

  watch: {
    value(val) {
      this.purchase = val;
    },

    purchase: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    this.purchase = this.value;
  },
};
</script>

<style>
</style>