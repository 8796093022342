<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <v-icon class="mr-3"> mdi-currency-usd </v-icon> Pagamentos
      </v-card-title>
      <v-card-text>
        <template v-for="(bill, index) in bills">
          <v-row class="d-flex align-center" :key="index">
            <v-col cols="3">
              <app-text-field v-model="bill.document" label="Documento" />
            </v-col>
            <v-col cols="3">
              <app-number-field v-model="bill.value" label="Valor" />
            </v-col>
            <v-col cols="5">
              <app-text-field
                v-model="bill.due_date"
                type="date"
                label="Vencimento"
              />
            </v-col>

            <v-btn
              @click="deleteBill(index)"
              v-if="index > 0"
              text
              small
              icon
              color="primary"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-row>
        </template>

        <v-btn
          @click="addBill()"
          small
          color="primary"
          text
          class="text-capitalize my-4"
        >
          <v-icon>mdi-plus </v-icon> Adicionar
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data() {
    return {
      bills: [
        {
          document: null,
          due_date: null,
          value: null,
        },
      ],
    };
  },

  watch: {
    value(val) {
      this.bills = val;
    },

    bills: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    this.bills = this.value;
  },

  methods: {
    addBill() {
      this.bills.push({
        document: null,
        due_date: null,
        value: null,
      });
    },

    deleteBill(index) {
      this.$delete(this.bills, index);
    },
  },
};
</script>

<style>
</style>