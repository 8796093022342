<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    width="800px"
  >
    <v-card-title primary-title>Adicionar Produto</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <ProductSearch v-model="form.product" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <app-number-field
            v-model="form.raw_quantity"
            label="Quantidade NF"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.raw_price"
            label="Preço da Nf"
            :precision="4"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.quantity_multiple"
            label="Múltiplo Compra"
            @input="makeCalculations()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <app-number-field
            :value="form.quantity"
            label="Quantidade"
            readonly
            :precision="4"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.price"
            label="Preço"
            :precision="4"
            @input="makeCalculations()"
            readonly
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.product_total"
            label="Total"
            readonly
          />
        </v-col>
        <v-col cols="4" offset="8">
          <app-number-field
            v-model="form.discount"
            label="Desconto"
            @input="makeCalculations()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <div>
            <h3>Sub Total</h3>
          </div>
          <div>
            <h3>{{ $format.decimal(form.net_total) }}</h3>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <app-number-field
            v-model="form.icms"
            label="ICMS (R$)"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            :value="form.icms_percent"
            label="ICMS(%)"
            readonly
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            v-model="form.icms_st"
            label="ST (R$)"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            :value="form.icms_st_percent"
            label="ST (%)"
            readonly
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            v-model="form.ipi"
            label="IPI (R$)"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            :value="form.ipi_percent"
            label="IPI (%)"
            readonly
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <app-number-field
            v-model="form.shipping"
            label="Frete (R$)"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            v-model="form.insurance"
            label="Seguro (R$)"
            @input="makeCalculations()"
          />
        </v-col>
        <v-col cols="3">
          <app-number-field
            v-model="form.expenses"
            label="Outras despesas (R$)"
            @input="makeCalculations()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between">
          <div>
            <h3>Total</h3>
          </div>
          <div>
            <h3>{{ $format.decimal(form.purchase_total) }}</h3>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="3">
          <app-text-field v-model="form.gtin" label="Ean" />
        </v-col>
        <v-col cols="3">
          <app-text-field
            v-model="form.manufacturer_id"
            label="Cod. Fornecedor"
          />
        </v-col>
        <v-col cols="3">
          <app-text-field v-model="form.weight_gross" label="Peso Bruto" />
        </v-col>
        <v-col cols="3">
          <app-text-field v-model="form.weight_net" label="Peso Líquido" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <app-text-field v-model="form.cfop" label="CFOP" />
        </v-col>
        <v-col cols="3">
          <app-text-field v-model="form.ncm" label="NCM" />
        </v-col>
        <v-col cols="3">
          <app-text-field v-model="form.cest" label="CEST" />
        </v-col>
        <v-col cols="3">
          <app-text-field v-model="form.cst" label="CST" />
        </v-col>
      </v-row>
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          class="text-capitalize mr-2"
          color="primary"
          :disabled="!form.product.id"
          @click="handleAddProduct()"
        >
          {{ index == null ? "Adicionar" : "Atualizar" }}
        </v-btn>
        <v-btn @click="drawer = false" text class="text-capitalize">
          Cancelar
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
export default {
  components: { ProductSearch },

  data() {
    return {
      drawer: false,
      index: null,
      form: {},
      baseForm: {
        product: {},
        xProd: null,
        manufacturer_id: null,
        gtin: null,
        unit: null,
        raw_quantity: 1,
        quantity_multiple: 1,
        quantity: 1,
        raw_price: 0,
        price: 0,
        product_total: 0,
        discount: 0,
        net_total: 0,
        icms_calc_base: 0,
        icms_aliquot: 0,
        icms: 0,
        icms_percent: 0,
        icms_st_calc_base: 0,
        icms_st_aliquot: 0,
        icms_st: 0,
        icms_st_percent: 0,
        ipi_calc_base: 0,
        ipi_aliquot: 0,
        ipi: 0,
        ipi_percent: 0,
        shipping: 0,
        expenses: 0,
        insurance: 0,
        purchase_total: 0,
        cfop: null,
        origin: null,
        cst: null,
        csosn: null,
        ncm: null,
        cest: null,
      },
    };
  },

  created() {
    this.resetForm();
  },

  computed: {
    quantity() {
      return this.form.quantity_multiple * this.form.raw_quantity;
    },

    netTotal() {
      return this.$calc.round(this.form.product_total - this.form.discount);
    },
  },

  methods: {
    open(data) {
      this.reset();
      this.drawer = true;
      if (data) {
        this.setProduct(data);
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setProduct(data) {
      this.index = data.index;
      this.form = JSON.parse(JSON.stringify(data.product));
    },

    handleAddProduct() {
      if (this.index == null) {
        this.addProduct();
      } else {
        this.updateProduct();
      }

      this.resetForm();
    },

    addProduct() {
      this.$emit("store", this.form);
    },

    updateProduct() {
      this.$emit("update", {
        index: this.index,
        product: this.form,
      });
      this.drawer = false;
    },

    resetForm() {
      this.index = null;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    validate() {
      console.log("validate.....??");
      // if (!this.form.product) {
      //   return false;
      // }

      // return true;
    },

    // **** calcs ****
    // ok
    makeCalculations() {
      this.setQuantity();
      this.setPrice();
      this.setProductTotal();
      this.setNetTotal();
      this.setPurchaseProductTotal();
    },

    setQuantity() {
      this.form.quantity = this.form.raw_quantity * this.form.quantity_multiple;
    },

    setPrice() {
      this.form.price = this.$calc.round(
        this.form.raw_price / this.form.quantity_multiple,
        4
      );
    },

    setProductTotal() {
      this.form.product_total = this.$calc.round(
        this.form.price * this.quantity
      );
    },

    setNetTotal() {
      this.form.net_total = this.$calc.round(
        this.form.product_total - this.form.discount
      );
    },

    setPurchaseProductTotal() {
      this.form.purchase_total =
        this.form.net_total +
        this.form.ipi +
        this.form.icms_st +
        this.form.shipping +
        this.form.expenses +
        this.form.insurance;
    },

    setTaxesPercents() {
      if (this.form.net_total > 0) {
        this.form.icms_percent = (this.form.icms * 100) / this.form.net_total;
        this.form.icms_st_percent =
          (this.form.icms_st * 100) / this.form.net_total;
        this.form.ipi_percent = (this.form.ipi * 100) / this.form.net_total;
      }
    },

    setTaxValue(valueField, percentField) {
      this.form[valueField] = this.$calc.round(
        (this.form.net_total * this.form[percentField]) / 100
      );
      this.setPurchaseProductTotal();
    },

    setTaxPercent(valueField, percentField) {
      if (this.form.net_total > 0) {
        this.form[percentField] =
          (this.form[valueField] * 100) / this.form.net_total;
      }
      this.setPurchaseProductTotal();
    },
  },
};
</script>

<style></style>
