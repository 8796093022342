<template>
  <div>
    <v-btn
      @click="handleXmlClick()"
      class="text-capitalize"
      outlined
      color="primary"
    >
      <v-icon> mdi-file-table-outline </v-icon>
      <span class="ml-2">{{ label }}</span>
    </v-btn>

    <input
      class="d-none"
      ref="fileInput"
      type="file"
      accept=".xml"
      @input="handleFileInput()"
    />
    <v-checkbox
      v-model="useProductPrice"
      label="Utilizar custo atual do produto"
      class="mt-2"
      :true-value="true"
      :false-value="false"
      :hide-details="true"/>
  </div>
</template>

<script>
export default {
  
  data: () => ({
    fileXml: [],
    fileName: null,
    useProductPrice : false,
  }),

  computed: {
    label() {
      return this.xmlName == null ? "Abrir arquivo XML" : this.xmlName;
    },
  },

  methods: {
    handleXmlClick() {
      this.$refs.fileInput.click();
    },

    handleFileInput() {
      let input = this.$refs.fileInput;
      let files = input.files;
      if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileXml = files[0];
          this.fileName = files[0].name;
          this.parseXml();
        };
        reader.readAsDataURL(files[0]);
      }
    },

    async parseXml() {
      
      this.$loading.start();
      
      let form = new FormData();

      form.append("xml", this.fileXml, "xml");
      form.append("useProductPrice", this.useProductPrice);

      await this.$http
        .store("purchase/parse-xml", form)
        .then((response) => {

          this.$emit("input", response);

          this.$loading.finish();
        })
        .catch((error) => {
          this.xmlError = true;
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>