<template>
  <div>
    <h3 class="my-6">Informações da Nota</h3>
    <v-row class="d-flex justify-space-between">
      <v-col cols="5">
        <v-row>
          <v-col>
            <CompanySelect v-model="form.company" ref="CompanySelect" />
          </v-col>
          <v-col cols="12"
            ><SupplierSearch v-model="form.supplier" ref="SupplierSearch"
          /></v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-row>
          <v-col>
            <app-text-field v-model="form.number" label="Numero da Nota" />
          </v-col>
          <v-col>
            <app-text-field v-model="form.serie" label="Série" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field
              v-model="form.emited_at"
              label="Data Emissão"
              type="date"
            />
          </v-col>
          <v-col>
            <app-text-field v-model="form.mod" label="Modelo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-text-field
              v-model="form.entry_at"
              label="Data Entrada"
              type="date"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
export default {
  components: {
    SupplierSearch,
    CompanySelect,
  },
  props: {
    value: {},
  },

  data() {
    return {
      form: {},
    };
  },

  watch: {
    value(val) {
      this.form = val;
    },

    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    this.form = this.value;
  },
};
</script>

<style>
</style>
<!--template>
  <v-card outlined>
    <v-card-title primary-title> Dados da Nota </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <app-text-field v-model="form.number" label="Numero da Nota" />
        </v-col>
        <v-col cols="3">
          <app-select v-model="form.type" :items="typeOptions" label="Tipo" />
        </v-col>
        <v-col cols="3">
       
        </v-col>
        <v-col cols="3">

        </v-col>

        <v-col cols="3">
          <app-text-field v-model="form.cfop" label="CFOP" />
        </v-col>

        <v-col cols="3">
          <app-number-field
            v-model="form.other_expenses"
            label="Outras Despesas"
          />
        </v-col>
        <v-col cols="3">
          <app-select
            v-model="form.operation"
            :items="operationsOptions"
            label="Operação"
          />
        </v-col>
        <v-col cols="3">
          <app-select
            :items="shippingMethodOptions"
            v-model="form.shipping_method"
            label="Transporte"
          />
        </v-col>

        <v-col>

          <v-btn
            v-if="!form.supplier.id && fileName"
            @click="$refs.SupplierSearch.addSupplier(form.supplier)"
            class="text-capitalize"
            color="error"
            x-small
            text
          >
            <v-icon>mdi-plus</v-icon> Adicionar Fornecedor
          </v-btn>
        </v-col>
        <v-col>
          <CompanySelect v-model="form.company" ref="CompanySelect" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</!--template>

<script>

export default {
  props: {
    fileName: {},
  },
  components: {
    SupplierSearch,
    CompanySelect,
  },

  data() {
    return {
      operationsOptions: [
        { value: "product_receipt", text: "Recebimento de mercadorias" },
        {
          value: "product_receipt_bonus",
          text: "Recebimento de mercadorias Bonificação",
        },
        {
          value: "repair_return_receipt",
          text: "Recebimento Retorno Conserto",
        },
      ],

      typeOptions: [
        { value: "invoice", text: "Nota Fiscal" },
        { value: "register", text: "Cadastro" },
      ],
      shippingMethodOptions: [
        {
          value: "0",
          text: "Contratação do Frete por conta do Remetente (CIF)",
        },
        {
          value: "1",
          text: "Contratação do Frete por conta do Destinatário (FOB)",
        },
        { value: "2", text: "Contratação do Frete por conta de Terceiros" },
        { value: "3", text: "Transporte Próprio por conta do Remetente" },
        { value: "4", text: "Transporte Próprio por conta do Destinatário" },
        { value: "9", text: "Sem Ocorrência de Transporte" },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.state.purchaseForm.form;
    },
  },

  methods: {
    setSupplier() {
      this.$store.commit("purchaseForm/setSupplier");
    },
  },
};
</script>

<style>
</style>