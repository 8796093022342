<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title primary-title> Vincular Produto </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <ProductSearch class="mt-5" v-model="product" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn
          @click="handleSave()"
          class="text-capitalize"
          color="primary"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
    <StoreProductForm
      @store="newProductStored($event)"
      ref="StoreProductForm"
    />
  </v-dialog>
</template>

<script>
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import StoreProductForm from "@/components/product/forms/StoreProductForm.vue";

export default {
  components: { ProductSearch, StoreProductForm },

  data() {
    return {
      dialog: false,

      product: null,
    };
  },

  methods: {
    open() {
      this.reset()
      this.dialog = true;
    },

    reset() {
      this.product = null;
    },

    handleSave() {
      this.$emit('input', this.product)
      this.dialog = false;
    },

  },
};
</script>

<style>
</style>