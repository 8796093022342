<template>
  <div>
    <template v-if="hasProducts">
      <v-data-table
        dense
        hide-default-footer
        disable-sort
        :headers="headers"
        :items="purchaseProducts"
        :item-class="checkIfNewProduct"
        :items-per-page="-1"
      >
        <template v-slot:[`item.name`]="{ item, index }">
          <div class="my-2">
            <template v-if="item.product">
              {{ item.product.code }} -
              {{ item.product.name }}
              <br />
              <small class="text--secondary" v-if="item.cfop"
                >CFOP {{ item.cfop }}</small
              >
              <small class="text--secondary" v-if="item.xProd">
                | {{ item.xProd }}</small
              >
            </template>
            <template v-else>
              {{ item.name }}
              <br />
              <small class="text--secondary">{{ item.gtin }}</small>
              <div>
                <v-btn
                  @click="$emit('linkProduct', index)"
                  small
                  style="padding-left: 0px"
                  text
                  class="text-capitalize"
                  color="primary"
                  ><v-icon class="mr-2">mdi-link-variant</v-icon> Vincular
                </v-btn>
                <v-btn
                  @click="$emit('store', index)"
                  small
                  style="padding-left: 0px"
                  text
                  class="text-capitalize"
                  color="primary"
                  ><v-icon class="mr-2">mdi-plus</v-icon> Cadastrar
                </v-btn>
              </div>
            </template>
          </div>
        </template>
        <!-- <template v-slot:[`item.cst`]="{ item }">
          {{ item.cst }}
        </template> -->
        <template v-slot:[`item.quantity`]="{ item }">
          {{ $format.decimal(item.quantity_multiple * item.raw_quantity) }}
        </template>

        <template v-slot:[`item.purchase_price`]="{ item }">
          {{ $format.decimal(item.price) }}
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          {{ $format.decimal(item.discount) }}
        </template>
        <template v-slot:[`item.shipping`]="{ item }">
          {{ $format.decimal(item.shipping) }}
        </template>
        <template v-slot:[`item.ipi_value`]="{ item }">
          {{ $format.decimal(item.ipi) }}
        </template>
        <template v-slot:[`item.st_value`]="{ item }">
          {{ $format.decimal(item.icms_st) }}
        </template>
        <template v-slot:[`item.icms_value`]="{ item }">
          {{ $format.decimal(item.icms) }}
        </template>
        <template v-slot:[`item.purchase_total`]="{ item }">
          {{ $format.decimal(item.purchase_total) }}
        </template>
        <template v-slot:[`item.action`]="{ item, index }">
          <template v-if="item.product">
            <v-btn
              @click.stop="$emit('editProduct', index)"
              text
              small
              icon
              color="primary"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn
              @click.stop="$emit('deleteProduct', index)"
              text
              small
              icon
              color="primary"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </template>
      </v-data-table>
    </template>
    <template>
      <v-btn
        @click="$emit('addProduct')"
        class="text-capitalize my-5"
        color="primary"
      >
        <v-icon>mdi-plus </v-icon> Adicionar Produto
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    purchaseProducts: {
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      headers: [
        { text: "Nome", value: "name", align: "start", width: "30%" },
        // { text: "Cst", value: "cst", align: "center" },
        // { text: "CFOP", value: "cfop", align: "center" },
        { text: "Quantidade", value: "quantity", align: "right" },
        { text: "Preço", value: "purchase_price", align: "right" },
        { text: "Desconto", value: "discount", align: "right" },
        { text: "Frete (R$)", value: "shipping", align: "right" },
        { text: "ICMS (R$)	", value: "icms_value", align: "right" },
        { text: "ST (R$)", value: "st_value", align: "right" },
        { text: "IPI (R$)	", value: "ipi_value", align: "right" },
        { text: "Total", value: "purchase_total", align: "center" },
        { text: "", value: "action", align: "right", width: "10%" },
      ],
    };
  },

  computed: {
    hasProducts() {
      return this.purchaseProducts.length > 0;
    },
  },
  methods: {
    checkIfNewProduct(item) {
      if (!item.product) {
        return "new-product";
      }
      return null;
    },
  },
};
</script>

<style>
.new-product {
  background-color: #eeeeee;
}
</style>